import type { FooterInfo, GroupInfo } from '@common/interface/footer'

import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { listFooterInfo } from '@lib/util/footer-handle'

export const useFooter = () => {
  const [infos, setInfos] = useState<FooterInfo[]>([])
  const [groupInfos, setGroupInfos] = useState<GroupInfo[]>([])

  const { data } = useQuery(['infos'], async () => await listFooterInfo())

  const handleFilterInfos = (footerInfos: FooterInfo[]) => {
    return footerInfos.filter((fi) => {
      if (fi.isActive) {
        if (fi.start_date == null && fi.end_date == null) {
          return fi
        } else if (fi.start_date != null) {
          if (new Date(fi.start_date).getTime() <= new Date().getTime()) {
            return fi
          }
          // eslint-disable-next-line no-dupe-else-if
        } else if (fi.start_date != null && fi.end_date != null) {
          const isStart =
            new Date(fi.start_date).getTime() <= new Date().getTime()
          const isNotEnd =
            new Date(fi.end_date).getTime() > new Date().getTime()
          if (isStart && isNotEnd) {
            return fi
          }
        }
      }
    })
  }

  useEffect(() => {
    if (!data || !data?.data) return
    if (data.data.count <= 0) return

    const groupMap = new Map<string, FooterInfo[]>()
    const filterInfos = handleFilterInfos(data.data.infos as FooterInfo[])

    const groupInfs = filterInfos.reduce((g, info) => {
      if (groupMap.has(info.category.id)) {
        const infos = groupMap.get(info.category.id)
        if (infos) {
          infos.push(info)
          groupMap.set(info.category.id, infos)
        }
      } else {
        groupMap.set(info.category.id, [info])
      }
      return groupMap
    }, groupMap)

    setInfos([...filterInfos])
    setGroupInfos([
      ...Array.from(groupInfs, ([key, value]) => ({
        category_id: key,
        infos: [...value]
      }))
    ])
  }, [data])

  return { infos, groupInfos }
}
