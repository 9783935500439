import React from 'react'
import { IconPropsType } from './icon-props'

export const MdKeyboardArrowDown = ({
  width = 9,
  height = 6
}: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 9 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.359985 0.76001L4.37999 4.78001L8.39999 0.76001"
        stroke="black"
        strokeMiterlimit="10"
      />
    </svg>
  )
}
