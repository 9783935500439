import { Box, Container, Flex, Text } from '@chakra-ui/react'
import { CONTAINER_MAX_WIDTH } from '@constants/container.constant'
import Image from 'next/image'
import { LogoIcon } from '@icons/logo-icon'
import React from 'react'
import { useResponsive } from '@common/hooks'

interface FooterProps {
  bg?: string
  color?: string
}

const JmlFooterCopyright: React.FC<FooterProps> = (props) => {
  const { xlMin } = useResponsive()

  const paddingX = xlMin ? 28 : 4

  return (
    <Box bg={props.bg ?? 'jmlPrimary'}>
      <Container
        color={'white'}
        py={8}
        justifyContent={'space-between'}
        display="flex"
        px={paddingX}
      >
        <Flex
          alignItems="center"
          justifyContent={'space-between'}
          gap={10}
          wrap={'wrap'}
        >
          <Image
            src={'/images/jml-logo-white.avif'}
            width={300}
            height={74}
            alt="habbit-logo-icon"
          />

          <Box>
            <Text textStyle="footerText" h={5} color={props.color ?? '#E5E4D7'}>
              JML Singapore Pte Ltd is part of the JML Group of Companies. UEN
              No.201003805D
            </Text>
            <Text textStyle="footerText" h={5} color={props.color ?? '#E5E4D7'}>
              Copyright @ 2021 JML Singapore Pte Ltd, All rights reserved.
            </Text>
          </Box>
        </Flex>
      </Container>
    </Box>
  )
}

export default JmlFooterCopyright
