import React from 'react'
import { IconPropsType } from './icon-props'

export const TikTokIcon = ({ width = 28, height = 28 }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 27 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8_76)">
        <path
          d="M0.0199919 14.4351C0.0239476 11.7674 0.818735 9.16087 2.30388 6.94487C3.78903 4.72887 5.89785 3.00294 8.36375 1.98527C10.8297 0.967608 13.5419 0.703897 16.1577 1.22748C18.7734 1.75106 21.1752 3.03843 23.0594 4.92683C24.9437 6.81524 26.2257 9.21989 26.7434 11.8368C27.2612 14.4537 26.9915 17.1654 25.9683 19.629C24.9452 22.0927 23.2146 24.1976 20.9953 25.6779C18.776 27.1581 16.1676 27.9471 13.5 27.9451C11.7272 27.9451 9.97191 27.5954 8.33448 26.9161C6.69705 26.2368 5.2097 25.2412 3.95757 23.9863C2.70544 22.7314 1.71314 21.2418 1.03747 19.6029C0.361806 17.9639 0.0160466 16.2078 0.0199919 14.4351ZM20.83 9.86509C20.7535 9.85713 20.6765 9.85713 20.6 9.86509C19.614 9.72062 18.7052 9.24863 18.02 8.52509C17.4722 7.99216 17.0191 7.36996 16.68 6.68509C16.6687 6.64996 16.6456 6.61982 16.6146 6.59978C16.5836 6.57973 16.5467 6.57101 16.51 6.57509H13.89V17.0351C13.895 17.4921 13.7737 17.9416 13.5394 18.334C13.3051 18.7263 12.9669 19.0463 12.5622 19.2586C12.1575 19.4709 11.702 19.5673 11.2459 19.537C10.7899 19.5068 10.3511 19.3511 9.97803 19.0872C9.60491 18.8233 9.31198 18.4614 9.13156 18.0415C8.95115 17.6216 8.89027 17.16 8.95565 16.7077C9.02103 16.2554 9.21013 15.8299 9.50206 15.4783C9.794 15.1267 10.1774 14.8625 10.61 14.7151C10.8761 14.6193 11.1572 14.5719 11.44 14.5751H12.37V11.8051C11.82 11.8051 11.27 11.8051 10.73 11.8851C10.0419 11.9719 9.37812 12.1957 8.77796 12.5434C8.1778 12.891 7.65341 13.3554 7.23578 13.9092C6.81814 14.4629 6.51573 15.0947 6.34642 15.7673C6.1771 16.4399 6.14431 17.1396 6.24999 17.8251C6.37479 18.8306 6.80211 19.7744 7.47536 20.5316C8.1486 21.2888 9.036 21.8236 10.02 22.0651C10.9843 22.3706 12.0186 22.3764 12.9862 22.0818C13.9539 21.7873 14.8095 21.2061 15.44 20.4151C16.2656 19.4525 16.6945 18.2121 16.64 16.9451V10.7951C17.6353 11.763 18.9287 12.3657 20.31 12.5051L20.83 9.86509Z"
          fill="#262726"
        />
      </g>
      <defs>
        <clipPath id="clip0_8_76">
          <rect
            width="26.98"
            height="26.98"
            fill="white"
            transform="translate(0.0200195 0.945068)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
