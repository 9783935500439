import React from 'react'
import { IconPropsType } from './icon-props'

export const SearchIcon = ({
  width = 18,
  height = 18,
  color
}: IconPropsType) => {
  const fillText = color ?? 'black'

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5_16)">
        <path
          d="M7.79001 13.52C11.2584 13.52 14.07 10.7084 14.07 7.24002C14.07 3.77167 11.2584 0.960022 7.79001 0.960022C4.32166 0.960022 1.51001 3.77167 1.51001 7.24002C1.51001 10.7084 4.32166 13.52 7.79001 13.52Z"
          stroke={fillText}
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M11.3741 12.3696L12.6257 11.118L17.7946 16.287C17.8921 16.3845 17.9469 16.5168 17.9469 16.6547C17.9469 16.7926 17.8921 16.9249 17.7946 17.0224L17.2784 17.5386C17.1809 17.6361 17.0486 17.6909 16.9107 17.6909C16.7728 17.6909 16.6406 17.6361 16.543 17.5386L11.3741 12.3696Z"
          fill={fillText}
        />
      </g>
      <defs>
        <clipPath id="clip0_5_16">
          <rect
            width="17.18"
            height="17.48"
            fill="white"
            transform="translate(0.76001 0.210022)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
