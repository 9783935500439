import { BACKEND_URL_STORE } from '@constants/server.constant'
import axios from 'axios'
import { isHabbitHostname } from './host'

export const listFooterInfo = async () => {
  const query: any = {}

  if (isHabbitHostname()) {
    query.is_habbit = true
  } else {
    query.is_jml = true
  }

  return await axios.get(`${BACKEND_URL_STORE}/footer/info`, { params: query })
}

export const getFooterInfoById = async (id: string) => {
  return await axios.put(`${BACKEND_URL_STORE}/footer/info/${id as string}`)
}
