export enum ContactType {
  WHATSAPP = 'whatsapp',
  PHONE = 'phone',
  EMAIL = 'email'
}

export const mapToLinkContact = (contact: ContactType, value: string) => {
  switch (contact) {
    case ContactType.WHATSAPP:
      return `https://wa.me/${value.replaceAll(' ', '').replace('+', '')}`
    case ContactType.PHONE:
      return `tel:${value.replaceAll(' ', '')}`
    case ContactType.EMAIL:
      return `mailto:${value}?subject=Habbit Customer Service`
    default:
      return '#'
  }
}
