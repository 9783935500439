import React from 'react'

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

interface GoogleReCaptchaProviderProps {
  children: React.ReactNode
}

const RecaptchaProvider: React.FC<GoogleReCaptchaProviderProps> = ({
  children
}) => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.NEXT_PUBLIC_CAPCHA_SITE_KEY || ''}
      scriptProps={{
        async: false,
        defer: true,
        appendTo: 'head',
        nonce: undefined
      }}
    >
      {children}
    </GoogleReCaptchaProvider>
  )
}

export default RecaptchaProvider
