import React from 'react'
import { IconPropsType } from './icon-props'

export const MdKeyboardArrowRight = ({
  width = 15,
  height = 31,
  color,
  strokeWidth
}: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.709969 31.8401L16.28 16.2801L0.709971 0.710085"
        stroke={color ?? 'black'}
        strokeWidth={strokeWidth ?? 2}
        strokeMiterlimit="10"
      />
    </svg>
  )
}
