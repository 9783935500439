import React from 'react'
import { IconPropsType } from './icon-props'

export const LongArrow = ({
  width = 39,
  height = 17,
  color
}: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 39 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8_90)">
        <path
          d="M0 8.13989H38.09"
          stroke={color || 'black'}
          strokeMiterlimit="10"
        />
        <path
          d="M30.31 15.9201L38.09 8.1401L30.31 0.350098"
          stroke={color || 'black'}
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_8_90">
          <rect width="38.8" height="16.27" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
