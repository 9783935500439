import React from 'react'
import { IconPropsType } from './icon-props'

export const LogoIcon = ({
  width = 193,
  height = 140,
  color
}: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 193 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M176.63 85C169.922 80.6072 162.701 77.0509 155.13 74.41C158.77 73.77 163.99 72.1 168.42 71.14C169.023 71.0031 169.59 70.7412 170.085 70.3714C170.581 70.0016 170.993 69.532 171.295 68.9929C171.598 68.4539 171.784 67.8574 171.842 67.242C171.899 66.6265 171.827 66.0058 171.63 65.42C171 63.42 169.96 61.32 166.82 57.86C159.48 49.76 149.66 43.17 140.7 36.86C132.231 30.9401 123.393 25.5659 114.24 20.77C96.099 11.1545 76.6856 4.16145 56.58 0C56.58 0 83 21 129.45 39C90.2 24.49 52.83 39 52.83 39L126.64 45.32C120.227 53.2702 111.995 59.5606 102.64 63.66C86.87 70.34 77.54 67.53 65.03 71.92C65.0104 70.1492 64.5209 68.4153 63.6115 66.8957C62.7021 65.3761 61.4055 64.1253 59.8542 63.271C58.3029 62.4168 56.5526 61.9899 54.7822 62.0339C53.0118 62.0779 51.2849 62.5912 49.7779 63.5214C48.271 64.4517 47.0381 65.7654 46.2053 67.3283C45.3725 68.8912 44.9697 70.6472 45.0381 72.4168C45.1064 74.1864 45.6435 75.9061 46.5944 77.4001C47.5452 78.8941 48.8758 80.1088 50.45 80.92C46.78 84.58 41.58 90.57 32.24 101.5C20 115.79 9.19 128.61 0 139.56C21.73 124.29 60.07 100.2 101.48 90C110.429 87.7009 119.584 86.2941 128.81 85.8C145.75 85.02 166.73 87.54 192.71 98.14C187.826 93.2073 182.437 88.8029 176.63 85ZM153.46 60.56C153.47 59.8917 153.677 59.2413 154.055 58.6904C154.434 58.1396 154.967 57.7129 155.587 57.4639C156.207 57.215 156.887 57.155 157.541 57.2913C158.196 57.4277 158.795 57.7545 159.264 58.2305C159.733 58.7065 160.051 59.3106 160.178 59.9668C160.305 60.623 160.235 61.302 159.977 61.9186C159.719 62.5351 159.284 63.0616 158.728 63.432C158.172 63.8023 157.518 63.9999 156.85 64C156.401 64 155.956 63.9107 155.541 63.7373C155.126 63.5638 154.751 63.3096 154.435 62.9895C154.12 62.6694 153.871 62.2897 153.704 61.8727C153.536 61.4556 153.453 61.0094 153.46 60.56Z"
        fill={color || '#DE4800'}
      />
    </svg>
  )
}
