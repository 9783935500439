import React from 'react'
import { IconPropsType } from './icon-props'

export const HomeBgText = ({ width = 537, height = 216 }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 537 216"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6_33)">
        <path
          d="M348.63 85C341.922 80.6072 334.701 77.0509 327.13 74.41C330.77 73.77 335.99 72.1 340.42 71.14C341.023 71.0031 341.59 70.7412 342.085 70.3714C342.581 70.0016 342.993 69.532 343.295 68.9929C343.598 68.4539 343.784 67.8574 343.842 67.242C343.899 66.6265 343.827 66.0058 343.63 65.42C343 63.42 341.96 61.32 338.82 57.86C331.48 49.76 321.66 43.17 312.7 36.86C304.231 30.9401 295.393 25.5659 286.24 20.77C268.099 11.1545 248.686 4.16145 228.58 0C228.58 0 255 21 301.45 39C262.2 24.49 224.83 39 224.83 39L298.64 45.32C292.227 53.2702 283.995 59.5606 274.64 63.66C258.87 70.34 249.54 67.53 237.03 71.92C237.01 70.1492 236.521 68.4153 235.611 66.8957C234.702 65.3761 233.405 64.1253 231.854 63.271C230.303 62.4168 228.553 61.9899 226.782 62.0339C225.012 62.0779 223.285 62.5912 221.778 63.5214C220.271 64.4517 219.038 65.7654 218.205 67.3283C217.373 68.8912 216.97 70.6472 217.038 72.4168C217.106 74.1864 217.643 75.9061 218.594 77.4001C219.545 78.8941 220.876 80.1088 222.45 80.92C218.78 84.58 213.58 90.57 204.24 101.5C192 115.79 181.19 128.61 172 139.56C193.73 124.29 232.07 100.2 273.48 90C282.429 87.7009 291.584 86.2941 300.81 85.8C317.75 85.02 338.73 87.54 364.71 98.14C359.826 93.2073 354.437 88.8029 348.63 85ZM325.46 60.56C325.47 59.8917 325.677 59.2413 326.055 58.6904C326.434 58.1396 326.967 57.7129 327.587 57.4639C328.207 57.215 328.887 57.155 329.541 57.2913C330.196 57.4277 330.795 57.7545 331.264 58.2305C331.733 58.7065 332.051 59.3106 332.178 59.9668C332.305 60.623 332.235 61.302 331.977 61.9186C331.719 62.5351 331.284 63.0616 330.728 63.432C330.172 63.8023 329.518 63.9999 328.85 64C328.401 64 327.956 63.9107 327.541 63.7373C327.126 63.5638 326.751 63.3096 326.435 62.9895C326.12 62.6694 325.871 62.2897 325.704 61.8727C325.536 61.4556 325.453 61.0094 325.46 60.56Z"
          fill="#DE4800"
        />
        <path
          d="M0 206.11V175H16.8V179.17H4.55V188.34H16.8V192.51H4.55V201.98H16.8V206.15L0 206.11Z"
          fill="white"
        />
        <path
          d="M38.27 206.11V193.4C38.27 188.47 36.33 186.16 32.46 186.16C28.59 186.16 26.31 188.98 26.31 193.36V206.11H21.93V182.79H23.78C24.3662 182.793 24.9279 183.026 25.3434 183.44C25.7588 183.853 25.9947 184.414 26 185V185.25H26.08C26.8861 184.225 27.9258 183.408 29.1123 182.867C30.2988 182.326 31.5975 182.076 32.9 182.14C38.5 182.14 42.62 185.67 42.62 192.14V206.14L38.27 206.11Z"
          fill="white"
        />
        <path d="M48.24 206.11V175H52.62V206.15L48.24 206.11Z" fill="white" />
        <path
          d="M58.26 180.1V175H62.64V180.14L58.26 180.1ZM58.26 206.1V182.79H62.64V206.11L58.26 206.1Z"
          fill="white"
        />
        <path
          d="M75.27 206.11L66 182.79H68.38C69.0518 182.79 69.7074 182.996 70.2582 183.38C70.8091 183.765 71.2286 184.309 71.46 184.94L76.78 199.46H76.86L82.06 184.96C82.2887 184.324 82.7083 183.774 83.2614 183.386C83.8145 182.997 84.4741 182.789 85.15 182.79H87.51L78.33 206.11H75.27Z"
          fill="white"
        />
        <path
          d="M93.25 196.34C93.6202 198.11 94.579 199.702 95.9701 200.857C97.3611 202.012 99.1024 202.661 100.91 202.7C102.268 202.71 103.604 202.351 104.775 201.663C105.945 200.974 106.908 199.982 107.56 198.79H111.94C111.105 201.095 109.583 203.088 107.581 204.502C105.579 205.916 103.191 206.683 100.74 206.7C97.5978 206.61 94.611 205.313 92.4003 203.079C90.1897 200.844 88.9253 197.843 88.87 194.7C88.87 187.33 94.43 182.2 100.61 182.2C102.197 182.173 103.773 182.465 105.246 183.057C106.718 183.65 108.057 184.531 109.183 185.65C110.309 186.769 111.2 188.102 111.802 189.57C112.404 191.039 112.706 192.613 112.69 194.2C112.707 194.929 112.667 195.658 112.57 196.38L93.25 196.34ZM108.32 193C108.139 191.127 107.267 189.388 105.874 188.123C104.481 186.858 102.667 186.157 100.785 186.157C98.9032 186.157 97.0888 186.858 95.6958 188.123C94.3027 189.388 93.4307 191.127 93.25 193H108.32Z"
          fill="white"
        />
        <path
          d="M133.37 206.11V193.4C133.37 188.47 131.43 186.16 127.56 186.16C123.69 186.16 121.41 188.98 121.41 193.36V206.11H117V182.79H118.85C119.144 182.789 119.435 182.845 119.706 182.957C119.978 183.068 120.225 183.232 120.433 183.44C120.641 183.647 120.807 183.893 120.919 184.164C121.032 184.436 121.09 184.726 121.09 185.02V185.27H121.17C121.976 184.244 123.015 183.427 124.202 182.886C125.388 182.344 126.687 182.096 127.99 182.16C133.59 182.16 137.71 185.69 137.71 192.16V206.16L133.37 206.11Z"
          fill="white"
        />
        <path
          d="M143.34 180.1V175H147.72V180.14L143.34 180.1ZM143.34 206.1V182.79H147.72V206.11L143.34 206.1Z"
          fill="white"
        />
        <path
          d="M169.65 206.11V193.4C169.65 188.47 167.72 186.16 163.84 186.16C159.96 186.16 157.7 188.98 157.7 193.36V206.11H153.32V182.79H155.17C155.758 182.79 156.322 183.022 156.74 183.436C157.157 183.85 157.395 184.412 157.4 185V185.25H157.49C158.295 184.224 159.335 183.406 160.521 182.864C161.708 182.323 163.007 182.075 164.31 182.14C169.91 182.14 174.03 185.67 174.03 192.14V206.14L169.65 206.11Z"
          fill="white"
        />
        <path
          d="M198.91 203C197.962 204.2 196.742 205.158 195.351 205.795C193.96 206.431 192.438 206.728 190.91 206.66C184.22 206.66 178.45 200.89 178.45 194.58C178.43 192.935 178.74 191.302 179.361 189.779C179.983 188.255 180.903 186.872 182.069 185.71C183.234 184.548 184.62 183.632 186.146 183.016C187.671 182.399 189.305 182.094 190.95 182.12C192.452 182.107 193.941 182.394 195.33 182.964C196.719 183.534 197.98 184.376 199.04 185.44V185.2C199.04 184.55 199.298 183.927 199.758 183.468C200.217 183.008 200.84 182.75 201.49 182.75H203.41V203C203.41 210.57 198.74 215.25 190.83 215.25C188.388 215.328 185.979 214.666 183.92 213.351C181.86 212.036 180.246 210.129 179.29 207.88H184.18C184.911 208.97 185.911 209.852 187.083 210.443C188.255 211.033 189.559 211.311 190.87 211.25C196.22 211.25 199.04 208.34 199.04 203H198.91ZM199 194.41C199.04 193.317 198.856 192.228 198.458 191.21C198.061 190.191 197.459 189.265 196.69 188.488C195.92 187.711 195 187.1 193.985 186.693C192.97 186.285 191.883 186.091 190.79 186.12C188.597 186.12 186.493 186.991 184.942 188.542C183.391 190.093 182.52 192.197 182.52 194.39C182.52 196.583 183.391 198.687 184.942 200.238C186.493 201.789 188.597 202.66 190.79 202.66C192.959 202.634 195.033 201.761 196.567 200.227C198.101 198.693 198.974 196.619 199 194.45V194.41Z"
          fill="white"
        />
        <path
          d="M239.12 206.11V192.56H226.28V206.11H221.73V175H226.28V188.43H239.12V175H243.66V206.15L239.12 206.11Z"
          fill="white"
        />
        <path
          d="M268.75 206.11V202.49H268.67C267.844 203.833 266.674 204.931 265.282 205.671C263.89 206.411 262.325 206.766 260.75 206.7C253.94 206.7 248.38 201.56 248.38 194.83C248.38 187.38 253.72 182.16 260.59 182.16C262.157 182.076 263.721 182.391 265.134 183.074C266.547 183.758 267.763 184.789 268.67 186.07H268.75V185.74C268.75 184.953 269.059 184.197 269.611 183.636C270.163 183.075 270.913 182.753 271.7 182.74H273.13V206.06L268.75 206.11ZM268.84 194.49C268.878 193.403 268.695 192.319 268.302 191.305C267.91 190.29 267.316 189.365 266.556 188.587C265.796 187.808 264.886 187.192 263.881 186.775C262.877 186.358 261.798 186.149 260.71 186.16C259.071 186.132 257.461 186.592 256.084 187.481C254.707 188.37 253.626 189.648 252.977 191.153C252.327 192.658 252.14 194.322 252.438 195.934C252.737 197.546 253.507 199.032 254.652 200.205C255.797 201.378 257.264 202.184 258.868 202.522C260.472 202.859 262.14 202.712 263.66 202.1C265.18 201.487 266.485 200.437 267.407 199.082C268.329 197.727 268.828 196.129 268.84 194.49Z"
          fill="white"
        />
        <path
          d="M279.61 213.9V182.79H281C281.695 182.79 282.361 183.066 282.853 183.557C283.344 184.049 283.62 184.715 283.62 185.41V186.41H283.71C284.636 185.078 285.879 183.996 287.325 183.262C288.772 182.527 290.378 182.162 292 182.2C298.95 182.2 304.08 187.59 304.08 194.78C304.109 196.345 303.826 197.901 303.247 199.356C302.668 200.81 301.805 202.135 300.708 203.252C299.612 204.37 298.303 205.257 296.859 205.863C295.416 206.468 293.866 206.78 292.3 206.78C288.76 206.78 285.6 205.35 284.09 202.78H284V213.9H279.61ZM299.69 194.49C299.712 192.331 298.886 190.249 297.391 188.692C295.895 187.134 293.848 186.225 291.69 186.16C287.32 186.16 283.95 190.07 283.95 194.41C283.891 195.479 284.05 196.549 284.417 197.555C284.784 198.561 285.352 199.481 286.086 200.261C286.82 201.04 287.705 201.663 288.687 202.09C289.669 202.517 290.727 202.74 291.798 202.745C292.869 202.751 293.929 202.538 294.915 202.121C295.902 201.704 296.793 201.091 297.535 200.319C298.276 199.547 298.854 198.632 299.231 197.63C299.608 196.628 299.778 195.56 299.73 194.49H299.69Z"
          fill="white"
        />
        <path
          d="M308.49 213.9V182.79H309.87C310.565 182.79 311.231 183.066 311.723 183.557C312.214 184.049 312.49 184.715 312.49 185.41V186.41H312.58C313.506 185.077 314.748 183.995 316.195 183.261C317.641 182.526 319.248 182.161 320.87 182.2C327.82 182.2 332.95 187.59 332.95 194.78C332.979 196.346 332.696 197.903 332.116 199.358C331.537 200.813 330.673 202.138 329.575 203.256C328.477 204.373 327.167 205.261 325.723 205.866C324.278 206.471 322.727 206.782 321.16 206.78C317.63 206.78 314.47 205.35 312.96 202.78H312.87V213.9H308.49ZM328.57 194.49C328.592 192.331 327.766 190.249 326.271 188.692C324.775 187.134 322.728 186.225 320.57 186.16C316.2 186.16 312.83 190.07 312.83 194.41C312.771 195.479 312.93 196.549 313.297 197.555C313.664 198.561 314.232 199.481 314.966 200.261C315.7 201.04 316.585 201.663 317.567 202.09C318.549 202.517 319.607 202.74 320.678 202.745C321.749 202.751 322.809 202.538 323.795 202.121C324.782 201.704 325.673 201.091 326.415 200.319C327.156 199.547 327.734 198.632 328.111 197.63C328.488 196.628 328.658 195.56 328.61 194.49H328.57Z"
          fill="white"
        />
        <path
          d="M340 213.9L343.41 205.65L334.1 182.79H336.72C337.342 182.788 337.95 182.979 338.459 183.337C338.968 183.696 339.353 184.203 339.56 184.79L345.68 200.93L352 184.73C352.224 184.159 352.615 183.669 353.122 183.323C353.628 182.977 354.227 182.792 354.84 182.79H357.47L344.57 213.9H340Z"
          fill="white"
        />
        <path
          d="M390.84 206.11V192.56H378V206.11H373.45V175H378V188.43H390.84V175H395.38V206.15L390.84 206.11Z"
          fill="white"
        />
        <path
          d="M420.47 206.11V202.49H420.39C419.565 203.831 418.397 204.929 417.006 205.669C415.616 206.409 414.054 206.765 412.48 206.7C405.66 206.7 400.1 201.56 400.1 194.83C400.1 187.38 405.45 182.16 412.31 182.16C413.877 182.076 415.441 182.391 416.854 183.074C418.267 183.758 419.483 184.789 420.39 186.07H420.47V185.83C420.47 185.034 420.786 184.271 421.349 183.709C421.911 183.146 422.674 182.83 423.47 182.83H424.8V206.15L420.47 206.11ZM420.56 194.49C420.598 193.403 420.415 192.319 420.022 191.305C419.63 190.29 419.036 189.365 418.276 188.587C417.516 187.808 416.606 187.192 415.601 186.775C414.597 186.358 413.518 186.149 412.43 186.16C410.791 186.132 409.181 186.592 407.804 187.481C406.427 188.37 405.346 189.648 404.697 191.153C404.047 192.658 403.86 194.322 404.158 195.934C404.457 197.546 405.227 199.032 406.372 200.205C407.517 201.378 408.984 202.184 410.588 202.522C412.192 202.859 413.86 202.712 415.38 202.1C416.9 201.487 418.205 200.437 419.127 199.082C420.049 197.727 420.548 196.129 420.56 194.49Z"
          fill="white"
        />
        <path
          d="M430.7 206.11V175H435.08V186H435.17C436.1 184.765 437.31 183.77 438.702 183.097C440.093 182.424 441.625 182.093 443.17 182.13C446.396 182.177 449.474 183.494 451.737 185.794C454 188.094 455.266 191.193 455.26 194.42C455.26 200.94 450.12 206.67 443.55 206.67C441.931 206.714 440.324 206.374 438.861 205.679C437.397 204.984 436.119 203.953 435.13 202.67H435V206.04L430.7 206.11ZM450.87 194.45C450.994 193.335 450.883 192.206 450.542 191.137C450.202 190.068 449.641 189.082 448.895 188.244C448.149 187.406 447.235 186.735 446.212 186.273C445.19 185.811 444.081 185.569 442.959 185.564C441.838 185.558 440.727 185.789 439.7 186.24C438.673 186.692 437.752 187.354 436.998 188.185C436.243 189.015 435.672 189.995 435.321 191.061C434.97 192.126 434.847 193.254 434.96 194.37C434.927 195.445 435.108 196.516 435.494 197.52C435.881 198.524 436.463 199.441 437.208 200.217C437.954 200.993 438.846 201.612 439.834 202.039C440.821 202.465 441.884 202.69 442.96 202.7C445.094 202.63 447.117 201.727 448.595 200.186C450.073 198.644 450.889 196.586 450.87 194.45Z"
          fill="white"
        />
        <path
          d="M459.67 180.1V175H464.05V180.14L459.67 180.1ZM459.67 206.1V182.79H464.05V206.11L459.67 206.1Z"
          fill="white"
        />
        <path
          d="M470.4 206.11V186.79H467.25V182.79H470.4V175H474.78V182.83H478.61V186.83H474.78V206.15L470.4 206.11Z"
          fill="white"
        />
        <path
          d="M500.71 206.11V202.49H500.63C499.804 203.833 498.634 204.931 497.242 205.671C495.85 206.411 494.285 206.766 492.71 206.7C485.89 206.7 480.34 201.56 480.34 194.83C480.34 187.38 485.68 182.16 492.54 182.16C494.109 182.075 495.674 182.389 497.089 183.073C498.503 183.757 499.722 184.788 500.63 186.07H500.71V185.83C500.71 185.432 500.789 185.038 500.943 184.67C501.097 184.303 501.322 183.97 501.606 183.691C501.89 183.412 502.227 183.192 502.597 183.044C502.966 182.896 503.362 182.823 503.76 182.83H505.09V206.15L500.71 206.11ZM500.8 194.49C500.838 193.403 500.655 192.319 500.262 191.305C499.87 190.29 499.276 189.365 498.516 188.587C497.756 187.808 496.846 187.192 495.841 186.775C494.837 186.358 493.758 186.149 492.67 186.16C491.031 186.132 489.421 186.592 488.044 187.481C486.667 188.37 485.586 189.648 484.937 191.153C484.287 192.658 484.1 194.322 484.398 195.934C484.697 197.546 485.467 199.032 486.612 200.205C487.757 201.378 489.224 202.184 490.828 202.522C492.432 202.859 494.1 202.712 495.62 202.1C497.14 201.487 498.445 200.437 499.367 199.082C500.289 197.727 500.788 196.129 500.8 194.49Z"
          fill="white"
        />
        <path
          d="M511.45 206.11V186.79H508.29V182.79H511.45V175H515.83V182.83H519.66V186.83H515.83V206.15L511.45 206.11Z"
          fill="white"
        />
        <path
          d="M531.78 189C531.775 188.616 531.693 188.236 531.538 187.885C531.383 187.533 531.159 187.216 530.879 186.953C530.599 186.689 530.269 186.485 529.908 186.353C529.548 186.22 529.164 186.161 528.78 186.18C528.435 186.151 528.087 186.194 527.759 186.305C527.431 186.416 527.129 186.593 526.872 186.825C526.615 187.058 526.409 187.341 526.266 187.656C526.122 187.972 526.046 188.314 526.04 188.66C526.04 190.66 528.04 191.23 530.38 191.94C533.66 192.94 536.69 194.94 536.69 199.27C536.69 203.77 533.41 206.72 528.87 206.72C527.776 206.756 526.687 206.567 525.669 206.166C524.651 205.764 523.727 205.158 522.953 204.385C522.178 203.611 521.571 202.688 521.168 201.67C520.765 200.653 520.575 199.564 520.61 198.47H525C525.21 201.29 526.64 202.72 529 202.72C529.437 202.755 529.877 202.698 530.29 202.552C530.704 202.406 531.082 202.175 531.4 201.873C531.718 201.571 531.968 201.205 532.136 200.8C532.303 200.394 532.383 199.958 532.37 199.52C532.37 197.92 531.15 196.87 528.37 195.99C522.65 194.18 521.68 191.69 521.68 189.04C521.68 184.91 525.17 182.18 529 182.18C529.921 182.145 530.84 182.295 531.702 182.621C532.564 182.948 533.352 183.443 534.019 184.079C534.687 184.715 535.22 185.478 535.588 186.323C535.955 187.168 536.15 188.078 536.16 189H531.78Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_6_33">
          <rect width="536.7" height="215.29" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
