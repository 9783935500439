import React, { Fragment } from 'react'
import { IconPropsType } from './icon-props'
import { Flex } from '@chakra-ui/react'

export interface StarIconPropsType extends IconPropsType {
  full?: boolean
  mid?: boolean
}

export const StarIcon = ({
  width = 30,
  height = 30,
  full,
  mid
}: StarIconPropsType) => {
  return (
    <Fragment>
      {full ? (
        <svg
          width={width}
          height={height}
          viewBox={`0 0 20 20`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.75 0.725006L12.76 6.83501L19.5 7.80501L14.63 12.565L15.78 19.275L9.75 16.105L3.73 19.275L4.88 12.565L0 7.80501L6.74 6.83501L9.75 0.725006Z"
            fill="#DE4800"
            stroke="#DE4800"
          />
        </svg>
      ) : mid ? (
        <Flex>
          <svg
            width={width}
            height={height}
            viewBox={`0 0 20 20`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <linearGradient id="grad">
                <stop offset="50%" stopColor={'#DE4800'} />
                <stop offset="50%" stopColor={'white'} />
              </linearGradient>
            </defs>
            <path
              d="M9.75 0.725006L12.76 6.83501L19.5 7.80501L14.63 12.565L15.78 19.275L9.75 16.105L3.73 19.275L4.88 12.565L0 7.80501L6.74 6.83501L9.75 0.725006Z"
              fill="url(#grad)"
              stroke="#B6B6B6"
            />
          </svg>
        </Flex>
      ) : (
        <svg
          height={height}
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.8198 1.83501L13.8398 7.94501L20.5798 8.91501L15.6998 13.675L16.8498 20.385L10.8198 17.215L4.79982 20.385L5.94982 13.675L1.06982 8.91501L7.80982 7.94501L10.8198 1.83501Z"
            stroke="#B6B6B6"
            strokeMiterlimit="10"
          />
        </svg>
      )}
    </Fragment>
  )
}
