import RouterUrl from './router.constant'

export const NAV_ITEMS_MAIN_LAYOUT = [
  {
    label: 'Our Story',
    href: RouterUrl.INFORMATION.OUR_STORY
  },
  {
    label: 'Shop',
    href: RouterUrl.SHOP.ROOT
  },
  {
    label: 'Deals',
    href: RouterUrl.DEALS.ROOT
  },
  {
    label: 'Brands',
    href: RouterUrl.BRAND.ROOT
  },
  {
    label: 'Read',
    href: RouterUrl.READ.ROOT
  },
  {
    label: 'Reward Points',
    href: RouterUrl.REWARD_POINTS.ROOT
  }
]

export const NAV_ITEMS_BRAND_LAYOUT = [
  {
    label: 'Home',
    href: RouterUrl.HOME.ROOT
  },
  {
    label: 'Shop',
    href: RouterUrl.SHOP.ROOT
  },
  {
    label: 'Brands',
    href: RouterUrl.BRAND.ROOT
  },
  {
    label: 'Reward Points',
    href: RouterUrl.REWARD_POINTS.ROOT
  }
]

export const JML_NAV_ITEMS_MAIN_LAYOUT = [
  {
    label: 'Shop',
    href: RouterUrl.SHOP.ROOT
  },
  {
    label: 'Deals',
    href: RouterUrl.DEALS.ROOT
  },
  {
    label: 'Brands',
    href: RouterUrl.BRAND.ROOT
  },
  {
    label: 'Tips',
    href: RouterUrl.READ.ROOT
  }
]
