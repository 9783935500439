export const FilterQueryParams = {
  CATEGORIES: 'categories',
  BRANDS: 'brands',
  PAGE: 'p',
  SORT: 'sort',
  SEARCH: 'q',
  ALL: 'view_all',
  COLLECTIONS: 'collections'
}

export const SortFilter = [
  {
    title: 'A -> Z',
    handle: 'a->z',
    value: 'title'
  },
  {
    title: 'Z -> A',
    handle: 'z->z',
    value: '-title'
  },
  {
    title: 'Newest -> Oldest',
    handle: 'newest->oldest',
    value: '-created_at'
  },
  {
    title: 'Oldest -> Newest',
    handle: 'oldest->newest',
    value: 'created_at'
  },
  {
    title: 'Lowest $ -> Highest $',
    handle: 'lowest$->highest$',
    value: 'price'
  },
  {
    title: 'Highest $ -> Lowest $',
    handle: 'highest$->lowest$',
    value: '-price'
  }
]
