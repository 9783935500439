import { Container } from '@chakra-ui/react'
// import { useResponsive } from '@common/hooks'

const MainContainer = ({
  children,
  ...rest
}: { children: React.ReactNode } & any) => {
  // const { xlLargeMin } = useResponsive()

  // const styles = xlLargeMin
  //   ? {
  //       borderLeftWidth: 1,
  //       borderRightWidth: 1,
  //       borderColor: '#b6b4b4',
  //     }
  //   : {}

  return (
    <Container px={0} maxW="2000px" {...rest}>
      {children}
    </Container>
  )
}

export default MainContainer
