import { Box, Container, Flex, Text } from '@chakra-ui/react'
import { CONTAINER_MAX_WIDTH } from '@constants/container.constant'
import Image from 'next/image'
import { LogoIcon } from '@icons/logo-icon'
import React from 'react'
import { useResponsive } from '@common/hooks'

interface FooterProps {
  bg?: string
  color?: string
}

const FooterCopyright: React.FC<FooterProps> = (props) => {
  const { xlMin } = useResponsive()

  const paddingX = xlMin ? 28 : 4

  return (
    <Box bg={props.bg ?? 'tuatara'}>
      <Container
        color={'white'}
        py={8}
        justifyContent={'space-between'}
        display="flex"
        maxW={CONTAINER_MAX_WIDTH}
        px={paddingX}
      >
        <Flex
          alignItems="end"
          justifyContent={'space-between'}
          gap={10}
          wrap={'wrap'}
        >
          <Image
            src={'/images/footer-img.png'}
            width={150}
            height={50}
            alt="habbit-logo-icon"
          />
          <Text textStyle="footerText" h={5} color={props.color ?? '#E5E4D7'}>
            Copyright @ 2023 JML Singapore Pte Ltd, All rights reserved.
          </Text>
        </Flex>
        <Flex>
          <LogoIcon color={props.color ?? '#de4800'} width={58} height={42} />
        </Flex>
      </Container>
    </Box>
  )
}

export default FooterCopyright
