import React from 'react'
import { IconPropsType } from './icon-props'

export const CartIcon = ({
  width = 18,
  height = 20,
  color,
  fill
}: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 20"
      fill={fill || 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5_22)">
        <path
          d="M1.72998 5.94504H17.25V15.815C17.25 16.5603 16.9539 17.275 16.427 17.802C15.9 18.329 15.1852 18.625 14.44 18.625H4.53998C3.79472 18.625 3.07999 18.329 2.55301 17.802C2.02603 17.275 1.72998 16.5603 1.72998 15.815V5.94504Z"
          stroke={color || 'black'}
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M6.07001 9.17502V4.69502C6.07265 3.7888 6.43381 2.92044 7.07462 2.27963C7.71542 1.63883 8.58378 1.27766 9.49001 1.27502C10.3962 1.27766 11.2646 1.63883 11.9054 2.27963C12.5462 2.92044 12.9074 3.7888 12.91 4.69502V9.17502"
          stroke={color || 'black'}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5_22">
          <rect
            width="17.02"
            height="18.85"
            fill="white"
            transform="translate(0.97998 0.525024)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
