import { useMediaQuery } from '@chakra-ui/react'

export const useResponsive = () => {
  const [smMin, mdMin, lgMin, xlMin, xlLargeMin] = useMediaQuery([
    '(min-width: 30em)',
    '(min-width: 48em)',
    '(min-width: 62em)',
    '(min-width: 80em)',
    '(min-width: 1800px)'
  ])

  const [smMax, mdMax, lgMax, xlMax, xlLargeMax] = useMediaQuery([
    '(max-width: 30em)',
    '(max-width: 48em)',
    '(max-width: 62em)',
    '(max-width: 80em)',
    '(max-width: 1536px)'
  ])

  return {
    smMin,
    mdMin,
    lgMin,
    xlMin,
    xlLargeMin,
    smMax,
    mdMax,
    lgMax,
    xlMax,
    xlLargeMax
  }
}
