import { IProductItem } from '@common/interface/product'
import { medusaClient } from '@lib/config'
import { useStore } from '@lib/context/store-context'
import { getPercentageDiff } from '@lib/util/get-precentage-diff'
import {
  Product,
  ProductCollection,
  Region,
  StoreGetProductsParams,
  StoreProductsListRes
} from '@medusajs/medusa'
import { useQuery } from '@tanstack/react-query'
import { formatAmount, useCart } from 'medusa-react'
import { ProductPreviewType } from 'types/global'
import { CalculatedVariant } from 'types/medusa'

type LayoutCollection = {
  id: string
  title: string
}

interface StoreGetProductsParamsInput extends StoreGetProductsParams {
  dataChange?: any
  price_list_id?: string[]
  orderPrice?: string
}

export interface DataLayoutProduct
  extends Omit<StoreProductsListRes, 'products'> {
  products: IProductItem[]
}

const fetchCollectionData = async (): Promise<LayoutCollection[]> => {
  let collections: ProductCollection[] = []
  let offset = 0
  let count = 1

  do {
    await medusaClient.collections
      .list({ offset })
      .then(({ collections: newCollections, count: newCount }) => {
        collections = [...collections, ...newCollections]
        count = newCount
        offset = collections.length
      })
      .catch((_) => {
        count = 0
      })
  } while (collections.length < count)

  return collections.map((c) => ({
    id: c.id,
    title: c.title
  }))
}

export const useNavigationCollections = () => {
  const queryResults = useQuery({
    queryFn: fetchCollectionData,
    queryKey: ['navigation_collections'],
    staleTime: Infinity,
    refetchOnWindowFocus: false
  })

  return queryResults
}

export const fetchFeaturedProducts = async (
  cartId: string,
  region: Region,
  query: StoreGetProductsParamsInput,
  saleChannels: string[]
): Promise<DataLayoutProduct> => {
  const data = await medusaClient.products.list({
    id: query.id,
    is_giftcard: false,
    cart_id: cartId,
    collection_id: query.collection_id,
    category_id: query.category_id,
    limit: query.limit ?? 999999,
    offset: query.offset,
    order: query.order,
    q: query.q,
    orderPrice: query.orderPrice,
    sales_channel_id: saleChannels
  } as any)

  const products = data?.products ?? ([] as Product[])

  const previewProducts = products
    .filter((p) => !!p.variants)
    .map((p) => {
      const variants = p.variants as CalculatedVariant[]

      const cheapestVariant = variants.reduce((acc, curr) => {
        if (acc.calculated_price > curr.calculated_price) {
          return curr
        }
        return acc
      }, variants[0])

      return {
        id: p.id,
        title: p.title,
        handle: p.handle,
        thumbnail: p.thumbnail,
        variants: p.variants ?? [],
        description: p.description ?? '',
        price: cheapestVariant
          ? {
              calculated_price: formatAmount({
                amount: cheapestVariant.calculated_price,
                region: region,
                includeTaxes: false
              }),
              original_price: formatAmount({
                amount: cheapestVariant.original_price,
                region: region,
                includeTaxes: false
              }),
              difference: getPercentageDiff(
                cheapestVariant.original_price,
                cheapestVariant.calculated_price
              ),
              price_type: cheapestVariant.calculated_price_type
            }
          : {
              calculated_price: 'N/A',
              original_price: 'N/A',
              difference: 'N/A',
              price_type: 'default'
            }
      }
    })

  return { ...data, products: previewProducts as IProductItem[] }
}

export const mapToProductPreview = (
  products: any[],
  region?: Region
): ProductPreviewType[] => {
  if (region) {
    return products
      .filter((p) => !!p.variants)
      .map((p) => {
        const variants = p.variants as CalculatedVariant[]

        const cheapestVariant = variants.reduce((acc, curr) => {
          if (acc.calculated_price > curr.calculated_price) {
            return curr
          }
          return acc
        }, variants[0])

        return {
          id: p.id,
          title: p.title,
          handle: p.handle,
          thumbnail: p.thumbnail,
          variants: p.variants ?? [],
          description: p.description ?? '',
          price: cheapestVariant
            ? {
                calculated_price: formatAmount({
                  amount: cheapestVariant.calculated_price,
                  region: region,
                  includeTaxes: false
                }),
                original_price: formatAmount({
                  amount: cheapestVariant.original_price,
                  region: region,
                  includeTaxes: false
                }),
                difference: getPercentageDiff(
                  cheapestVariant.original_price,
                  cheapestVariant.calculated_price
                ),
                price_type: cheapestVariant.calculated_price_type
              }
            : {
                calculated_price: 'N/A',
                original_price: 'N/A',
                difference: 'N/A',
                price_type: 'default'
              }
        }
      })
  }
  return []
}

export const useFeaturedProductsQuery = (
  query: StoreGetProductsParamsInput,
  keepPreviousData?: boolean
) => {
  const { cart } = useCart()
  const { saleChannels } = useStore()

  const {
    limit,
    offset,
    category_id,
    type_id,
    collection_id,
    dataChange,
    order,
    q,
    id,
    price_list_id,
    orderPrice
  } = query

  console.log('query', query)

  const cardId = cart ? cart.id : ''
  const cartRegion: any = cart ? cart.region : {}

  return useQuery(
    [
      'layout_featured_products',
      cart?.id,
      cart?.region,
      offset,
      limit,
      category_id,
      type_id,
      collection_id,
      dataChange,
      order,
      q,
      id,
      price_list_id,
      orderPrice
    ],
    () => fetchFeaturedProducts(cardId, cartRegion, query, saleChannels),
    {
      enabled: !!cart?.id && !!cart?.region,
      staleTime: 2 * 60 * 1000,
      refetchOnWindowFocus: false,
      keepPreviousData: !!keepPreviousData
    }
  )
}
