import { FilterQueryParams } from '@constants/filter.constant'
import RouterUrl from '@constants/router.constant'
import { useRouter } from 'next/router'

const useFilterQueryParams = (): any => {
  const router = useRouter()

  const categories = router?.query[FilterQueryParams.CATEGORIES]
  const brands = router?.query[FilterQueryParams.BRANDS]
  const sort = router?.query[FilterQueryParams.SORT]
  const page = router?.query[FilterQueryParams.PAGE] ?? 1
  const search = router?.query[FilterQueryParams.SEARCH]
  const all = router?.query[FilterQueryParams.ALL]
  const collections = router?.query[FilterQueryParams.COLLECTIONS]

  const addQuery = (name: string, handle: string, query?: any) => {
    let pathname = RouterUrl.SHOP.ROOT

    if (
      router?.pathname === RouterUrl.DEALS.ROOT ||
      router?.pathname === RouterUrl.FLASH_SALE.ROOT ||
      router?.pathname === `${RouterUrl.BRAND.ROOT}/[handle]`
    ) {
      pathname = router?.pathname
    }

    // Reset page pagination when adding new filter
    router.query.p = '1'

    if (query && router?.query) {
      const handles = (query as string)
        .split(',')
        .map((c: string) => c.toLowerCase())
      if (!handles.includes(handle)) {
        handles.push(handle)
        router.query[name] = handles.join(',')
        router.push({
          pathname,
          query: {
            ...router?.query
          }
        })
      } else {
        router.push({
          pathname,
          query: {
            ...router?.query
          }
        })
      }
    } else {
      router.query[name] = handle
      router.push({
        pathname,
        query: {
          ...router?.query
        }
      })
    }
  }

  const removeQuery = (name: string) => {
    if (router?.query) {
      if (router.query[name]) {
        delete router.query[name]
        router.push({
          pathname: router.pathname,
          query: {
            ...router.query
          }
        })
      }
    }
  }

  const clearAllQuery = (pathname?: string) => {
    router.replace({
      pathname: pathname ?? router.pathname,
      query: {}
    })
  }

  const addCategory = (handle: string) => {
    const cate_handle = handle.toLowerCase()
    addQuery(FilterQueryParams.CATEGORIES, cate_handle, categories)
  }

  const addBrand = (handle: string) => {
    const brand_handle = handle.toLowerCase()
    addQuery(FilterQueryParams.BRANDS, brand_handle, brands)
  }

  const addCollection = (handle: string) => {
    const collectHandle = handle.toLowerCase()
    addQuery(FilterQueryParams.COLLECTIONS, collectHandle, collections)
  }

  const addSort = (handle: string) => {
    // Reset page pagination when adding new filter
    router.query.p = '1'

    const sort_handle = handle.toLowerCase()
    if (router?.query) {
      router.query[FilterQueryParams.SORT] = sort_handle
      router.push({
        pathname: router.pathname,
        query: {
          ...router.query
        }
      })
    }
  }

  const addPage = (page: number) => {
    if (router?.query) {
      router.query[FilterQueryParams.PAGE] = page.toString()

      router.push({
        pathname: router.pathname,
        query: {
          ...router.query
        }
      })
    }
  }

  const addSearch = (q: string) => {
    // Reset page pagination when adding new filter
    router.query.p = '1'

    const search = q.toLowerCase()
    if (router?.query) {
      router.query[FilterQueryParams.SEARCH] = search

      const pathname = router.pathname

      if (
        pathname === RouterUrl.SHOP.ROOT ||
        pathname === RouterUrl.DEALS.ROOT ||
        pathname === RouterUrl.FLASH_SALE.ROOT
      ) {
        router.replace({
          pathname,
          query: {
            ...router.query
          }
        })
      } else {
        router.replace({
          pathname: RouterUrl.SHOP.ROOT,
          query: {
            ...router.query
          }
        })
      }
    }
  }

  const addAll = (input?: string) => {
    if (router?.query) {
      removeQuery(FilterQueryParams.ALL)
      router.query[FilterQueryParams.ALL] = input ?? 'products'
      router.query[FilterQueryParams.PAGE]
      router.query[FilterQueryParams.COLLECTIONS]
      router.push({
        pathname: router.pathname,
        query: {
          ...router.query
        }
      })
    }
  }

  return {
    removeQuery,
    addCategory,
    addBrand,
    addSort,
    addPage,
    addSearch,
    addAll,
    addCollection,
    clearAllQuery,
    categories,
    brands,
    collections,
    sort,
    page,
    search,
    all
  }
}

export default useFilterQueryParams
