import React from 'react'
import { IconPropsType } from './icon-props'

export const FacebookIcon = ({ width = 28, height = 28 }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8_62)">
        <path
          d="M16.4 28.0002V18.4602H19.61L20.21 14.5202H16.4C16.4 14.5202 16.4 14.4502 16.4 14.4202V11.8702C16.3787 11.4983 16.4509 11.127 16.61 10.7902C16.7428 10.5484 16.9288 10.3399 17.154 10.1805C17.3792 10.0211 17.6377 9.91499 17.91 9.87021C18.1594 9.84555 18.4106 9.84555 18.66 9.87021H20.33V6.48022L19.96 6.42022C19.44 6.36022 18.96 6.30021 18.4 6.26021C18.06 6.26021 17.73 6.26021 17.4 6.26021C16.5285 6.23377 15.6617 6.39757 14.86 6.74021C13.9532 7.14551 13.2148 7.85219 12.77 8.74021C12.4498 9.3627 12.256 10.0424 12.2 10.7402C12.2 11.0502 12.15 11.3602 12.15 11.6702C12.15 12.6002 12.15 13.5202 12.15 14.4502V14.6102H8.68998C8.68998 14.7202 8.68998 18.3602 8.68998 18.5502H12.11C12.11 18.6502 12.11 27.8702 12.11 28.0802C8.87034 27.5292 5.93854 25.8271 3.85368 23.287C1.76883 20.7469 0.671096 17.5395 0.762344 14.2546C0.853591 10.9697 2.12768 7.82817 4.35033 5.40769C6.57298 2.98722 9.59475 1.45054 12.86 1.08021C14.6558 0.855582 16.4786 0.999558 18.2169 1.50334C19.9552 2.00712 21.5725 2.86013 22.97 4.01022C25.6829 6.21578 27.424 9.39633 27.82 12.8702C28.2467 16.3802 27.2947 19.9187 25.1646 22.7408C23.0345 25.563 19.8924 27.4484 16.4 28.0002Z"
          fill="#262726"
        />
      </g>
      <defs>
        <clipPath id="clip0_8_62">
          <rect
            width="27.31"
            height="27.13"
            fill="white"
            transform="translate(0.609985 0.870117)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
