import React from 'react'
import { IconPropsType } from './icon-props'

export const MdKeyboardArrowLeft = ({
  width = 15,
  height = 31,
  color,
  strokeWidth
}: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.98 0.709961L1.40997 16.27L16.98 31.84"
        stroke={color ?? 'black'}
        strokeWidth={strokeWidth ?? 2}
        strokeMiterlimit="10"
      />
    </svg>
  )
}
