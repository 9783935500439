import React, { Fragment } from 'react'
import { Box } from '@chakra-ui/react'
import { useAccount } from '@lib/context/account-context'

interface PropsType {
  isLogin?: boolean
  children: React.ReactNode
  filter?: boolean
}

const AuthFilter = ({
  isLogin,
  children,
  filter,
  ...rest
}: PropsType & any) => {
  const { customer } = useAccount()

  return (
    <Fragment {...rest}>
      {filter ? (
        <Fragment>
          {isLogin && customer && children}
          {!isLogin && !customer && children}
        </Fragment>
      ) : (
        <Fragment>
          {customer && (customer as any).is_verify_otp === true && children}
        </Fragment>
      )}
    </Fragment>
  )
}

export default AuthFilter
